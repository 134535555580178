import styled from "styled-components";
import { forMobile } from "../../common/breakpoints";

export const BellIcon = styled.img`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  max-width: 1120px;
  display: flex;
  margin-bottom: 4px;
`;

export const FixTop = styled.div`
  margin-top: 60px;
  position: fixed;
  width: 100%;
  top: 58;
  left: 0;
  z-index: 999;
`;

export const Talk = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: "#191919";
  margin-bottom: ${(props) => (props.loading ? 0 : "16px")};
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #191919;

  ${forMobile} {
    margin-bottom: 16px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${forMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  cursor: pointer;
  width: 210px;

  ${forMobile} {
    width: 100%;
  }

  ${(props) => props.dark && `background-color: #031888`}
`;
