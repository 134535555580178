import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TopNav from "../../common/components/TopNav";
import { baseUrl, routeConfig } from "../../routes";
import {
  Wrapper,
  ConversationW,
  DialogWrap,
  TopWrap,
  ButtonIcon,
  ButtonTitle,
  Divider,
  RowTalk,
  UserTalk,
  AITalk,
  IconWrap,
  MagicIcon,
  Revise,
  ReviseTitle,
} from "../Session/Conversation/styled";
import {
  BellIcon,
  FixTop,
  Talk,
  Title,
  Row,
  Button,
} from "./ReviewDetail_styled";

const ReviewDetail = () => {
  // Get the userId param from the URL.
  let { sessionId } = useParams();
  const navigate = useNavigate();
  const [conversation, setConversation] = useState([]);
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    // check login or not
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(routeConfig.home);
    } else {
      getMessages(token);
    }
  }, [navigate]);

  const getMessages = async (tokenGet) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/message/session/${sessionId}`, options);
    const json = await res.json();
    // console.log("json messages all::::", json);

    if (res.ok && json.data?.length > 0) {
      // console.log("顯示複習對話！");
      // 組合對話 data
      formateData(json.data);
    } else {
      if (res.status === 401) {
        // console.log("Login expired, plz login again, review detail fail!!!!!");
        // Login again
        localStorage.clear();
        navigate(routeConfig.home);
      } else {
        // console.log("review detail fail!!!!!");
        const json = await res.json();
        Swal.fire({
          title: "Error!",
          text: json?.error || "review detail fail QQ",
          icon: "error",
          confirmButtonText: "Try again",
          confirmButtonColor: "#031888",
        });
      }
    }
  };

  const formateData = (data) => {
    let talkList = [];
    data.forEach((dialog) => {
      if (dialog.sentMsgPurpose === 0) {
        let addTalk = [
          {
            title: "Human",
            transcript: dialog.sentMsg,
            fixed: dialog.revisedSentMsg,
          },
          {
            title: "AI",
            transcript: dialog.receivedMsg,
          },
        ];

        talkList = talkList.concat(addTalk);
      }
    });

    setConversation(talkList);
  };

  // text-to-speech （文字轉講話）
  var msg = new SpeechSynthesisUtterance();
  msg.rate = 1;
  msg.pitch = 1;
  msg.lang = "en-GB";
  // msg.voiceURI = 'Bells'
  // msg.name = 'Bells'
  const voices = speechSynthesis
    .getVoices()
    .filter((voice) => voice.lang === "en-GB");
  msg.voice = voices.find((v) => v.name === "Google UK English Female");

  let timeoutResumeInfinity;

  // 每七秒重新開始，避免中斷
  const resumeInfinity = () => {
    window.speechSynthesis.pause();
    window.speechSynthesis.resume();
    timeoutResumeInfinity = setTimeout(resumeInfinity, 7000);
  };

  msg.onstart = function (event) {
    resumeInfinity();
  };

  msg.onend = function (event) {
    clearTimeout(timeoutResumeInfinity);
  };

  const replay = (sentence) => {
    // console.log("replay", msg);
    window.speechSynthesis.cancel();
    if (sentence) {
      msg.text = sentence
        .replace("ai: ", "")
        .replace("AI", "")
        .replace("Bot", "")
        .replace("Robot", "")
        .replace("Computer", "");
      window.speechSynthesis.speak(msg);
    }
  };

  // 叫ai別再講了，停下
  const pause = () => {
    // setIsPlay(!isPlay);
    window.speechSynthesis.cancel();
  };

  return (
    <>
      <TopNav />
      <Wrapper>
        <FixTop>
          <TopWrap>
            <BellIcon
              src="../assets/images/back_Icon.png"
              alt="back icon"
              onClick={() => navigate(-1)}
            />
            <Row>
              <Title>JCAI 課堂教室</Title>

              <Button dark={isHide} onClick={() => setIsHide(!isHide)}>
                {isHide ? (
                  <ButtonIcon
                    src="../assets/images/hide_Icon_white.png"
                    alt="hide_Icon_white image"
                  />
                ) : (
                  <ButtonIcon
                    src="../assets/images/hide_Icon.png"
                    alt="hide_Icon image"
                  />
                )}
                <ButtonTitle dark={isHide}>
                  {isHide ? "顯示文字" : "隱藏文字"}
                </ButtonTitle>
              </Button>
            </Row>
          </TopWrap>
          <Divider />
        </FixTop>
        <DialogWrap>
          <ConversationW>
            {conversation.map((Human, index) => {
              return (
                <>
                  {Human.title === "Human" ? (
                    <RowTalk>
                      <UserTalk>
                        <Talk loading={Human.fixed === null}>
                          {/* What type of food do you enjoy to eat? Western or
                          Asian? */}
                          {Human.transcript}
                        </Talk>
                        {Human.fixed && (
                          <Revise>
                            <ReviseTitle>
                              <MagicIcon
                                marginTitle
                                src="../assets/images/magic_new_Icon.png"
                                alt="magic image"
                              />
                              語句魔法棒
                            </ReviseTitle>
                            <Talk>
                              {/* What type of food do you enjoy to eat? Western or
                              Asian? */}
                              {Human.fixed}
                            </Talk>
                            <IconWrap>
                              <MagicIcon
                                marginRight
                                src="../assets/images/pause_Icon.png"
                                alt="pause_Icon image"
                                onClick={pause}
                              />
                              <MagicIcon
                                src="../assets/images/reload_black_Icon.png"
                                alt="reload_black_Icon image"
                                onClick={() => replay(Human.fixed)}
                              />
                            </IconWrap>
                          </Revise>
                        )}
                      </UserTalk>
                    </RowTalk>
                  ) : (
                    <AITalk>
                      {isHide ? null : (
                        <Talk>
                          {/* I’m interested in Asian food, Western food is not my
                          thing. */}
                          {Human.transcript}
                        </Talk>
                      )}
                      <IconWrap>
                        <MagicIcon
                          marginRight
                          src="../assets/images/pause_Icon.png"
                          alt="pause_Icon image"
                          onClick={pause}
                        />
                        <MagicIcon
                          src="../assets/images/reload_black_Icon.png"
                          alt="reload_black_Icon image"
                          onClick={() => replay(Human.transcript)}
                        />
                      </IconWrap>
                    </AITalk>
                  )}
                </>
              );
            })}
          </ConversationW>
        </DialogWrap>
      </Wrapper>
    </>
  );
};

export default ReviewDetail;
