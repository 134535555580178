import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { MenuLinks } from "../../constants";
import { forMobile, greaterThanMobile, forMiniTablet } from "../../breakpoints";

const Icon = styled.div`
  width: 48px;
  height: 48px;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Children = styled.div`
  height: 48px;

  ${forMobile} {
    height: 48px;
    display: flex;
    align-items: center;
  }
`;

const Wrapper = styled.header`
  align-items: center;
  height: 48px;
  background-color: #fff;
  position: relative;
  z-index: 1;

  ${(props) =>
    css`
      ${forMobile} {
        height: 48px;
        padding: ${props.hasIcon ? "0px" : "0 16px"};
      }

      ${greaterThanMobile} {
        ${Icon} {
          ${props.hasIcon && "display: none;"}
        }
      }
    `}
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

const Block = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 48px;
  position: relative;
  z-index: 1;

  ${(props) =>
    css`
      ${forMobile} {
        height: 48px;
        padding: ${props.hasIcon ? "0px" : "0 16px"};
      }

      ${greaterThanMobile} {
        ${Icon} {
          ${props.hasIcon && "display: none;"}
        }
      }
    `}
`;

const Home = styled.a`
  text-decoration: none;
`;

const Logo = styled.img`
  display: block;
  width: 31px;
  height: 31px;
  ${forMobile} {
    height: 26px;
    width: 26px;
  }
`;

const FeatureWrap = styled.div`
  margin-left: -300px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;

  ${forMobile} {
    display: none;
  }
  ${forMiniTablet} {
    margin-left: -150px;
  }
`;

const Feature = styled.p`
  font-size: 16px;
  line-height: 23.17px;
  color: #191919;
  text-align: center;
  margin-right: 36px;
  ${(props) => props.right && "margin-right: 0px;"}

  ${forMobile} {
    display: none;
  }
`;

const Header = ({ className, homeLink, children, leftIcon }) => (
  <React.Fragment>
    <Wrapper className={className} hasIcon={leftIcon}>
      <Block>
        <Icon>{leftIcon}</Icon>
        <>
          {homeLink ? (
            <Home href={homeLink}>
              <Logo src="../assets/images/logo.png" alt="logo image" />
            </Home>
          ) : (
            <Logo src="../assets/images/logo.png" alt="logo image" />
          )}
          <FeatureWrap>
            {MenuLinks.map((option, idx) => (
              <a href={option.url}>
                <Feature> {option.key}</Feature>
              </a>
            ))}
          </FeatureWrap>
        </>
        <Children>{children}</Children>
      </Block>
    </Wrapper>
  </React.Fragment>
);

Header.propTypes = {
  className: PropTypes.string,
  homeLink: PropTypes.string,
  leftIcon: PropTypes.element,
};

export default Header;
