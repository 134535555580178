import styled from "styled-components";
import { forMobile, greaterThanMobile } from "../../common/breakpoints";

export const Wrap = styled.div`
  background: rgba(96, 150, 253, 0.05);
  padding-top: 50px;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.blur &&
    `
  filter: blur(3px);
  -webkit-filter: blur(3px);
  `}

  ${(props) => props.height && `height:100vh;`}
`;

export const SessionListWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px;
  max-width: 1120px;
  flex-wrap: wrap;
  width: 100%;
  ${forMobile} {
    flex-direction: column;
  }
`;

export const SessionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  width: 31%;
  height: 488px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);
  border-radius: 10px;
  margin: 24px 24px 0px 0px;
  ${forMobile} {
    width: 100%;
  }
`;
export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 16px;
`;

export const PhraseSessionWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
//   background-color: rgba(170, 182, 253, 0.2);
  ${props=>props.phrases&&`background-color: rgba(170, 182, 253, 0.2);`}
  border-radius: 5px;
  height: 98px;
  width: 295px;
  font-size:15px;
`;

export const PhraseSession = styled.div`
  display: flex;
  flex-direction: row;
  height: 94px;
  overflow: scroll;
`;

export const Button = styled.a`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  /* White/fff */
  width: 100%;

  background: #ffffff;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;

  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  /* Primary01 */

  color: #031888;
`;

export const ClassTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 1120px;
  margin: 30px 10px -20px 30px;
`;

export const EmptyWrap = styled.div`
  padding-top: 160px;
  padding-bottom: 135px;
`;

export const EmptyPage = styled.div`
  padding: 0px 85px 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Doll = styled.img`
  margin-bottom: 38px;
  width: 205px;
  height: 260px;
`;

export const StartText = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #6096fd;
`;
