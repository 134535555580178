import React from "react";
import { Overlay, OverlayBackground, Wrap } from "../styled";
import { Title, Description, BellIcon, Row } from "../StartAlert/styled";
import { Button, ButtonTitle } from "../FinishAlert/styled";

const LeaveAlert = ({ actionHandler, getSummarize }) => {
  return (
    <Overlay>
      <OverlayBackground isTransparentBackground={true}>
        <Wrap>
          <BellIcon
            src="../assets/images/notification_Icon.png"
            alt="notification icon"
          />
          <Title>您確定要離開課堂嗎？</Title>
          <Description last>
            如您確定要離開此課堂，請點選「確定」，當您確認離開此課堂後，課堂剩餘之分鐘數將不會返還。
          </Description>
          <Row>
            <Button onClick={getSummarize}>
              <ButtonTitle>確定</ButtonTitle>
            </Button>
            <Button dark onClick={actionHandler}>
              <ButtonTitle dark>回到教室</ButtonTitle>
            </Button>
          </Row>
        </Wrap>
      </OverlayBackground>
    </Overlay>
  );
};

export default LeaveAlert;
