import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../common/components/Alert/StartAlert";
import LeaveAlert from "../../../common/components/Alert/LeaveAlert";
import FinishAlert from "../../../common/components/Alert/FinishAlert";
import TopNav from "../../../common/components/TopNav";
import { baseUrl, routeConfig } from "../../../routes";
import { SentMsgPurpose } from "../../../common/constants";
import Countdown from "../../../common/components/CountDown";
import {
  Wrapper,
  Wrap,
  ConversationW,
  DialogWrap,
  FixTop,
  Timer,
  Time,
  TopWrap,
  Title,
  Row,
  Button,
  ButtonIcon,
  ButtonTitle,
  Divider,
  EmptyPage,
  Doll,
  StartText,
  TalkPress,
  PhoneIcon,
  FixBottom,
  HolePage,
  RecordWrap,
  RowTitle,
  RecordIcon,
  RecordText,
  TalkWrap,
  BottomFix,
  RowTalk,
  UserTalk,
  AITalk,
  AITitle,
  Talk,
  IconWrap,
  MagicIcon,
  Revise,
  ReviseTitle,
  LoadingWrap,
} from "./styled";

const Conversation = () => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition({
    continuous: true,
  });
  const [tokenGet, setTokenGet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingGrammar, setLoadingGrammar] = useState(false);
  const [isFirstlaunch, setIsFirstlaunch] = useState(true);
  const [isLeave, setIsLeave] = useState(false);
  const [firstPress, setFirstPress] = useState(false);
  const [startTalk, setStartTalk] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [showMagic, setShowMagic] = useState(true);
  const [conversation, setConversation] = useState([
    // {
    //   title:'Human',
    //   transcript:initString
    // }
  ]);
  const [sessionSetting, setSessionSetting] = useState({});
  const [summarize, setSummarize] = useState({
    summarizeSentence: "",
    summarizePhrase: "",
  });
  const [isFinish, setIsFinish] = useState(false);
  const [couldClick, setCouldClick] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFirstlaunch) {
      // SpeechRecognition.startListening({ language: 'zh-TW' })
      SpeechRecognition.startListening({ language: "en-US" });
    }
  }, isFirstlaunch);

  useEffect(() => {
    // check login or not
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(routeConfig.home);
    } else {
      setTokenGet(token);
      getAllSession(token);
    }
  }, []);

  // text-to-speech （文字轉講話）
  var msg = new SpeechSynthesisUtterance();
  msg.rate = 1;
  msg.pitch = 1;
  msg.lang = "en-GB";
  // msg.voiceURI = 'Bells'
  // msg.name = 'Bells'
  let voices = speechSynthesis
    .getVoices()
    .filter((voice) => voice.lang === "en-GB");
  // msg.voice = voices[1]; // 1 女 2 男 3 以後都是 低音專業男
  msg.voice = voices.find((v) => v.name === "Google UK English Female");
  if (!msg.voice) {
    msg.lang = "en-US";
    voices = speechSynthesis
      .getVoices()
      .filter((voice) => voice.lang === "en-US");
    msg.voice = voices.find((v) => v.name === "Samantha");
  }
  if (!msg.voice) {
    msg.lang = "en-GB";
    voices = speechSynthesis
      .getVoices()
      .filter((voice) => voice.lang === "en-GB");
    msg.voice = voices.find(
      (v) =>
        v.name === "Microsoft Libby Online (Natural) - English (United Kingdom)"
    );
  }

  let timeoutResumeInfinity;

  // 每七秒重新開始，避免中斷
  const resumeInfinity = () => {
    window.speechSynthesis.pause();
    window.speechSynthesis.resume();
    timeoutResumeInfinity = setTimeout(resumeInfinity, 7000);
  };

  msg.onstart = function (event) {
    resumeInfinity();
  };

  msg.onend = function (event) {
    clearTimeout(timeoutResumeInfinity);
  };

  // 移到最下方
  const scrollToBottom = () => {
    document.documentElement.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  // 移到最上方
  const scrollToTop = () => {
    document.documentElement.scrollIntoView({ behavior: "smooth" });
  };

  // 開始監聽人講話，如果有講到repeat 123，就不存，否則存下對話（originalConversion代表包含所有對話內容）
  const startListening = () => {
    scrollToBottom();
    if (!firstPress) {
      setFirstPress(true);
    }

    // console.log("listening:", listening);
    if (listening) {
      if (transcript) {
        if (
          transcript.indexOf("repeat 123") === -1 &&
          transcript.indexOf("repeat 1 2 3") === -1
        ) {
          let originalConversion = [...conversation];
          originalConversion.push({
            title: "Human",
            transcript,
          });
          setConversation(originalConversion);
          scrollToBottom();
        } else {
          resetTranscript();
        }
      }

      SpeechRecognition.stopListening();
      setStartTalk(false);
    } else {
      // console.log("start listening");
      SpeechRecognition.startListening({ continuous: true, language: "en-US" });
      setStartTalk(true);
    }
  };

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  async function grammarFix(sentMsgId, index) {
    setLoadingGrammar(true);
    setCouldClick(false);
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(
      `${baseUrl}/message/session/${sessionSetting.sessionId}/sentMsg/${sentMsgId}/revise`,
      options
    );
    const json = await res.json();
    // console.log("fixed:", json);
    setLoadingGrammar(false);

    let fixedBack = "";
    if (json.data?.revisedSentMsg) {
      fixedBack = json.data.revisedSentMsg;
      msg.text = fixedBack
        .replace("ai: ", "")
        .replace("AI", "")
        .replace("Bot", "")
        .replace("Robot", "")
        .replace("Computer", "");
    }
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(msg);

    let originalConversion = [...conversation];
    originalConversion[index].fixed = fixedBack;
    setConversation(originalConversion);
    setCouldClick(true);
    return fixedBack;
  }

  async function sendMessageBeginning(sessionSetting) {
    try {
      scrollToBottom();

      // 送出talk的api
      const data = {
        messagePurpose: SentMsgPurpose.TEACHER_FREE_TALK_OPENING,
      };
      const options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${tokenGet}`,
        },
      };
      const res = await fetch(
        `${baseUrl}/message/session/${sessionSetting.sessionId}`,
        options
      );
      const json = await res.json();
      // console.log("beginning freetalk::: json ai back:", json);
    } catch (e) {
      console.error(e);
    }
  }

  async function sendMessage(message) {
    try {
      scrollToBottom();
      setLoading(true);
      setShowMagic(false);

      // 送出talk的api
      const data = {
        message: message || "",
      };
      const options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${tokenGet}`,
        },
      };
      const res = await fetch(
        `${baseUrl}/message/session/${sessionSetting.sessionId}`,
        options
      );
      const json = await res.json();
      // console.log("json ai back:", json);

      setLoading(false);

      let aiBack = "";
      if (json.data?.length > 0 && json.data[0]?.receivedMsg) {
        aiBack = json.data[0].receivedMsg;
        msg.text = aiBack
          .replace("ai: ", "")
          .replace("AI", "")
          .replace("Bot", "")
          .replace("Robot", "")
          .replace("Computer", "");

        let originalConversion = [...conversation];
        originalConversion[originalConversion.length - 1].sentMsgId =
          json.data[0]?.sentMsgId;
        originalConversion.push({
          title: "AI",
          transcript: aiBack,
        });
        setConversation(originalConversion);
        scrollToBottom();
      }

      setShowMagic(true);

      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(msg);
      // // console.log("conversation::", conversation);
      resetTranscript();
    } catch (error) {
      console.error(error);
      // alert(error);
      Swal.fire({
        title: "Error!",
        text: "AI回答出錯，請忽略繼續講下句話",
        icon: "error",
        confirmButtonText: "Try again",
        confirmButtonColor: "#031888",
      });
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    checkMicrophoneAccess();
    window.speechSynthesis.cancel();
    SpeechRecognition.stopListening();
    // sendMessage(initString)
  }, []);

  const downHandler = ({ key }) => {
    if (key === "Shift") {
      startListening();
      // console.log(123);
    }
    // console.log(key);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener("keyup", downHandler);
  }, []);

  // 監聽listening，一但沒在監聽，且還有transcript，就送出資訊給後端
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // console.log(listening, transcript);

    if (!listening) {
      if (transcript !== "") {
        sendMessage(transcript);
        // let originalConversion = [...conversation]
        // originalConversion.push({
        //   title:'Human',
        //   transcript
        // })
        // setConversation(originalConversion)
        resetTranscript();
        // console.log("conversation::", conversation);
      }
    }
  }, [listening]);

  const startConversation = async (classType) => {
    setIsFirstlaunch(false);

    // 幫他開啟對話
    let dataGet;
    if (classType.type === "Coupon") {
      // console.log("開啟 coupon 對話");
      // create session (with coupon) api
      dataGet = {
        primaryLang: "en", // TODO: could choose language
        couponType: classType.subType,
      };
    } else {
      // create session (with paid sessionType) api
      // console.log("開啟 正式 對話");
      dataGet = {
        primaryLang: "en", // TODO: could choose language
        sessionType: classType.subType,
      };
    }

    const options = {
      method: "POST",
      body: JSON.stringify(dataGet),
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/learning-session`, options);
    const jsonSession = await res.json();
    // console.log("jsonSession:", jsonSession);
    let sessionId = jsonSession.data._id;
    let duration = jsonSession.data.duration;
    // console.log("get session id::::", sessionId);
    if (sessionId) {
      // save start session
      const sessionVariable = {
        sessionId: sessionId,
        duration: duration,
      };

      // console.log("拿到對話設定：：：", sessionVariable);
      setSessionSetting(sessionVariable);
      // sendMessageBeginning(sessionVariable)
    }

    // 重新 call api 來避免使用者 refresh 導致數量不一致
    getAllSession(tokenGet);
  };

  function checkMicrophoneAccess() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        // console.log("Microphone is accessible!");
      })
      .catch(function (error) {
        console.error("Microphone is not accessible:", error);
        // 在這裡可以顯示開啟麥克風的通知
      });
  }

  const getAllSession = async (tokenGet) => {
    // get account available classes api
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/account/available-class`, options);
    const json = await res.json();
    // console.log("json class type::::", json);

    if (res.ok) {
      // console.log("存更新後剩餘課堂！！！");

      const classAllGet = json.data;
      localStorage.setItem("classAll", JSON.stringify(classAllGet));
    } else {
      if (res.status === 401) {
        // console.log( "Login expired, plz login again, conversation getAllSession fail!!!!!");
        // Login again
        localStorage.clear();
        navigate(routeConfig.home);
      } else {
        if (!res.ok) {
          // console.log("conversation getAllSession fail!!!!!");
          const json = await res.json();
          Swal.fire({
            title: "Error!",
            text: json?.error || "conversation get all session fail QQ",
            icon: "error",
            confirmButtonText: "Try again",
            confirmButtonColor: "#031888",
          });
        }
      }
    }
  };

  // 重新叫ai再講一次這句話
  const replay = (sentence) => {
    // console.log("replay", msg);
    window.speechSynthesis.cancel();
    if (sentence) {
      msg.text = sentence
        .replace("ai: ", "")
        .replace("AI", "")
        .replace("Bot", "")
        .replace("Robot", "")
        .replace("Computer", "");
      window.speechSynthesis.speak(msg);
    }
  };

  // 叫ai別再講了，停下
  const pause = () => {
    // setIsPlay(!isPlay);
    window.speechSynthesis.cancel();
  };

  const getSummarizeFromAlert = () => {
    setIsLeave(false);
    getSummarize();
  };

  const getSummarize = async () => {
    scrollToTop();
    pause();

    setCouldClick(false);
    setIsFinish(true);
    let sum = {
      summarizeSentence: "...",
      summarizePhrase: "...",
    };
    setSummarize(sum);

    // console.log("getSummarize:::::");

    try {
      const dataGet = {
        messagePurpose: SentMsgPurpose.SUMMARIZE_CONVERSATION,
      };

      const options = {
        method: "POST",
        body: JSON.stringify(dataGet),
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${tokenGet}`,
        },
      };

      const res = await fetch(
        `${baseUrl}/message/session/${sessionSetting.sessionId}`,
        options
      );

      if (res.status !== 200) {
        throw new Error("拿取總結錯誤，不等於200");
      }

      const json = await res.json();
      // console.log("json summarize::::::", json);

      // TODO: 存學習總覽 or call api 拿全部的學習紀錄和總覽。這邊先用傳值即可
      if (json.data?.length > 0 && json.data[0]?.receivedMsg) {
        sum = {
          summarizeSentence: json.data[0].receivedMsg,
          summarizePhrase: json.data[1].receivedMsg,
        };

        setSummarize(sum);
      }
    } catch (e) {
      console.log("e:::", e);
      sum = {
        summarizeSentence: "抱歉程式有誤，因此總結沒拿到",
        summarizePhrase: "抱歉程式有誤，因此片語沒拿到",
      };

      setSummarize(sum);
    }
  };

  const timeFormat = (ms) => {
    let fmt = {};
    let minute = parseInt(ms, 10);
    let second = 0;

    if (minute <= 60) {
      fmt = minute < 10 ? `0${minute}` : minute;
    } else {
      second = Math.floor(minute / 60);
      second = second < 10 ? `${second}` : second;
      minute = Math.floor(minute % 60);
      minute = minute < 10 ? `0${minute}` : minute;
      // fmt = `${second}:${minute}`;
      fmt = {
        startingMinutes: parseInt(second, 10),
        startingSeconds: parseInt(minute, 10),
      };
    }

    return fmt;
  };

  return (
    <>
      <TopNav blur={isFirstlaunch || isFinish || isLeave} />
      <Wrapper blur={isFirstlaunch || isFinish || isLeave}>
        <FixTop>
          <Timer isFinish={isFinish}>
            課程時間剩餘{" "}
            <Time>
              {sessionSetting?.duration ? (
                <Countdown
                  ms={timeFormat(sessionSetting.duration)}
                  isFinish={() => (couldClick ? getSummarize() : null)}
                />
              ) : null}
            </Time>
          </Timer>
          <TopWrap>
            <Title>JCAI 課堂教室</Title>
            <Row>
              <Button dark={isHide} onClick={() => setIsHide(!isHide)}>
                {isHide ? (
                  <ButtonIcon
                    src="../assets/images/hide_Icon_white.png"
                    alt="hide_Icon_white image"
                  />
                ) : (
                  <ButtonIcon
                    src="../assets/images/hide_Icon.png"
                    alt="hide_Icon image"
                  />
                )}
                <ButtonTitle dark={isHide}>
                  {isHide ? "顯示文字" : "隱藏文字"}
                </ButtonTitle>
              </Button>
              <Button
                onClick={() => {
                  setIsLeave(true);
                  scrollToTop();
                }}
              >
                <ButtonIcon
                  src="../assets/images/arrowLeft_Icon.png"
                  alt="arrowLeft_Icon image"
                />
                <ButtonTitle>離開教室</ButtonTitle>
              </Button>
            </Row>
          </TopWrap>
          <Divider />
        </FixTop>

        {conversation.length === 0 && !firstPress ? (
          <Wrap>
            <EmptyPage>
              <Doll
                src="../assets/images/man_taking_photo.png"
                alt="man_taking_photo image"
              />
              <StartText>點擊下方按鈕開始對話！</StartText>
            </EmptyPage>

            {/* // <>
          //   <HolePage>

          //     <div>hhh</div>
          //   </HolePage>
          //   <BottomFix />
          // </> */}
          </Wrap>
        ) : (
          <DialogWrap>
            <ConversationW>
              {conversation.map((Human, index) => {
                return (
                  <>
                    {Human.title === "Human" ? (
                      <RowTalk>
                        <UserTalk>
                          <Talk>
                            {/* What type of food do you enjoy to eat? Western or
                          Asian? */}
                            {Human.transcript}
                          </Talk>
                          {Human.fixed ? (
                            <Revise>
                              <ReviseTitle>
                                <MagicIcon
                                  marginTitle
                                  src="../assets/images/magic_new_Icon.png"
                                  alt="magic image"
                                />
                                語句魔法棒
                              </ReviseTitle>
                              <Talk>
                                {/* What type of food do you enjoy to eat? Western or
                              Asian? */}
                                {Human.fixed}
                              </Talk>
                              <IconWrap>
                                <MagicIcon
                                  marginRight
                                  src="../assets/images/pause_Icon.png"
                                  alt="pause_Icon image"
                                  onClick={pause}
                                />
                                {/* {isPlay ? (
                                <MagicIcon
                                  marginRight
                                  src="../assets/images/pause_Icon.png"
                                  alt="pause_Icon image"
                                  onClick={pause}
                                />
                              ) : (
                                <MagicIcon
                                  marginRight
                                  src="../assets/images/play_Icon.png"
                                  alt="pause_Icon image"
                                  onClick={pause}
                                />
                              )} */}
                                <MagicIcon
                                  src="../assets/images/reload_black_Icon.png"
                                  alt="reload_black_Icon image"
                                  onClick={() => replay(Human.fixed)}
                                />
                              </IconWrap>
                            </Revise>
                          ) : (
                            <IconWrap>
                              {showMagic ? (
                                <MagicIcon
                                  src="../assets/images/magic_new_Icon.png"
                                  alt="magic image"
                                  // onClick={() => setIsRevise(!isRevise)}
                                  onClick={() =>
                                    couldClick
                                      ? grammarFix(Human.sentMsgId, index)
                                      : null
                                  }
                                  loading={loadingGrammar}
                                />
                              ) : (
                                <MagicIcon
                                  src="../assets/images/magic_gray.svg"
                                  alt="magic gray image"
                                />
                              )}
                            </IconWrap>
                          )}
                        </UserTalk>
                      </RowTalk>
                    ) : (
                      <AITalk>
                        {isHide ? null : (
                          <Talk>
                            {/* I’m interested in Asian food, Western food is not my
                          thing. */}
                            {Human.transcript}
                          </Talk>
                        )}
                        <IconWrap>
                          <MagicIcon
                            marginRight
                            src="../assets/images/pause_Icon.png"
                            alt="pause_Icon image"
                            onClick={pause}
                          />
                          {/* {isPlay ? (
                          <MagicIcon
                            marginRight
                            src="../assets/images/pause_Icon.png"
                            alt="pause_Icon image"
                            onClick={pause}
                          />
                        ) : (
                          <MagicIcon
                            marginRight
                            src="../assets/images/play_Icon.png"
                            alt="pause_Icon image"
                            onClick={pause}
                          />
                        )} */}
                          <MagicIcon
                            src="../assets/images/reload_black_Icon.png"
                            alt="reload_black_Icon image"
                            onClick={() => replay(Human.transcript)}
                          />
                        </IconWrap>
                      </AITalk>
                    )}
                  </>
                );
              })}
            </ConversationW>
          </DialogWrap>
        )}

        {loading ? (
          <DialogWrap>
            <LoadingWrap>
              <AITalk loading>
                <Talk loading>...</Talk>
              </AITalk>
            </LoadingWrap>
          </DialogWrap>
        ) : null}

        <FixBottom>
          {startTalk ? (
            <RecordWrap>
              <RowTitle>
                <RecordIcon
                  src="../assets/images/record_Icon.png"
                  alt="record_Icon image"
                />
                您正在說話
              </RowTitle>
              {/* <RecordText>What type of food do you enjoy t</RecordText> */}
              <RecordText>{transcript}</RecordText>
            </RecordWrap>
          ) : null}
          <TalkWrap>
            <TalkPress onClick={startListening}>
              {startTalk ? (
                <PhoneIcon
                  src="../assets/images/bi_soundwave.png"
                  alt="bi_soundwave image"
                />
              ) : (
                <PhoneIcon
                  src="../assets/images/ion_mic.png"
                  alt="ion_mic image"
                />
              )}
            </TalkPress>
          </TalkWrap>
        </FixBottom>
      </Wrapper>
      {isFirstlaunch && <Alert actionHandler={startConversation} />}
      {isLeave && (
        <LeaveAlert
          actionHandler={() => setIsLeave(false)}
          getSummarize={() => getSummarizeFromAlert()}
        />
      )}
      {isFinish && <FinishAlert summarizeGet={summarize} />}
    </>
  );
};

export default Conversation;
