import React from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Overlay, OverlayBackground, Wrap } from "../styled";
import { routeConfig } from "../../../../routes";
import { ENCOURAGEMENT_LIST } from "../../../constants";
import { Row } from "../StartAlert/styled";
import {
  HeadTitle,
  SubTitle,
  Divider,
  ReviewIcon,
  TitleWrapper,
  Title,
  Description,
  Phrases,
  PhrasesWrap,
  Button,
  ButtonTitle,
} from "./styled";

const FinishAlert = ({ summarizeGet }) => {
  const navigate = useNavigate();
  const navToHome = () => {
    navigate(routeConfig.home);
  };

  const navToReview = () => {
    navigate(routeConfig.sessionList);
  };

  let isLoading = summarizeGet.summarizeSentence === "...";

  return (
    <Overlay>
      <OverlayBackground isTransparentBackground={true}>
        <Wrap>
          {isLoading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <ClipLoader
                color={"#A3A3A3"}
                loading={isLoading}
                size={60}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p style={{ marginTop: "16px", color: "#686868" }}>
                稍等一下，載入中...
              </p>
            </div>
          ) : (
            <>
              <HeadTitle>恭喜您完成了學習！</HeadTitle>
              <SubTitle>
                {ENCOURAGEMENT_LIST[Math.floor(Math.random() * 4)]}
              </SubTitle>
              <Divider />
              <TitleWrapper>
                <ReviewIcon
                  src="../assets/images/note_Icon.png"
                  alt="note_Icon image"
                />
                <Title>課程總結</Title>
              </TitleWrapper>
              <Description loading={isLoading}>
                {summarizeGet.summarizeSentence}
              </Description>
              <TitleWrapper>
                <ReviewIcon
                  src="../assets/images/pencil_Icon.png"
                  alt="pencil_Icon image"
                />
                <Title>片語學習</Title>
              </TitleWrapper>
              <PhrasesWrap>
                <Phrases loading={isLoading}>
                  {summarizeGet.summarizePhrase}
                </Phrases>
              </PhrasesWrap>
              <Row>
                <Button dark onClick={navToHome}>
                  <ButtonTitle dark>離開教室</ButtonTitle>
                </Button>
                <Button onClick={navToReview}>
                  <ButtonTitle>前往學習總覽</ButtonTitle>
                </Button>
              </Row>
            </>
          )}
        </Wrap>
      </OverlayBackground>
    </Overlay>
  );
};

export default FinishAlert;
