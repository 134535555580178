/*  localStorage
token: String,
email: String,

classAll:[{
count: 2,
subType: "5_MIN_COUPON",
type: "Coupon",
},
{
count: 3,
subType: "One15MinClass",
type: "Payment",
}],
*/
import { routeConfig } from "../routes";

export const SentMsgPurpose = {
  NORMAL: 0,
  TEACHER_FREE_TALK_OPENING: 1,
  SUMMARIZE_CONVERSATION: 2,
  LIST_POSSIBLE_IMPROVEMENTS: 3,
  RATE_THE_CONVERSATION: 4,
};

export const AccountStatus = {
  Registered: 0,
  Active: 1,
  Suspended: 2,
};

export const START_ENCOURAGEMENT = {
  1: "我打賭你不敢開口說英文！因為人都害怕自己太優秀。",
  2: "說英文可能一開始會有挑戰性，但只要多練習，你就會變得更有自信。",
  3: "記住，學習新語言時每個人都有起點，如果你一開始不是很完美，不要感到沮喪。",
  4: "說英文可以讓你與來自世界各地的人交流，這會是一個非常有價值的經驗。",
  5: "學習英語是對自己和未來的投資。英語流利程度越高，越多個人和職業發展機會向你敞開。",
};

export const ENCOURAGEMENT = {
  1: "明天的你，會感謝今天的你！",
  2: "成功，是每一步的累積！繼續加油！",
  3: "行動是成功的階梯，行動越多，登得越高！",
  4: "堅持不懈，是踏上勝利的通途！加油～！",
  5: "用更多的知識資產眺望未來！",
};

export const ENCOURAGEMENT_LIST = [
  ENCOURAGEMENT[1],
  ENCOURAGEMENT[2],
  ENCOURAGEMENT[3],
  ENCOURAGEMENT[4],
  ENCOURAGEMENT[5],
];

export const MenuLinks = [
  {
    key: "開始上課",
    url: routeConfig.conversation,
    icon: "start_class_Icon",
  },
  {
    key: "學習總覽",
    url: routeConfig.sessionList,
    icon: "review_Icon",
  },
  {
    key: "兌換/購買課程",
    url: routeConfig.buyPlan,
    icon: "money_Icon",
  },
];
