import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TopNav from "../../common/components/TopNav";
import Alert from "../../common/components/Alert/LoginAlert";
import Footer from "../../common/components/Footer";
import { baseUrl, routeConfig } from "../../routes";
import { AccountStatus } from "../../common/constants";
import {
  Wrap,
  TotalWrap,
  TopWrap,
  Title,
  Email,
  FeatureWrap,
  LeftInfo,
  DollRightBlank,
  DollRight,
  DollRightest,
  DollLeft,
  FeatureTitle,
  TitleDescription,
  Button,
  ButtonIcon,
  ButtonTitle,
  FeatureList,
  ListTitle,
  ListDescription,
  Row,
  Item,
  ItemImag,
  ItemTitle,
  SpecialTitle,
  Input,
  ErrorBlock,
  ErrorText,
  InputSection,
  Submit,
  Field,
  PurchaseWrap,
  ClassOption,
  ClassTitle,
  ClassDescription,
  ClassPriceWrap,
  PriceSymbol,
  PriceDollar,
  PriceNumber,
  ButtonPurchase,
  ClassPriceNote,
  ClassOptionWrap,
} from "./styled";

const Purchase = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [tokenGet, setTokenGet] = useState(null);
  const [emailGet, setEmailGet] = useState(null);
  const [formDataError, setFormDataError] = useState({ title: "" });
  const [formData, setFormData] = useState({ title: "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const [couldClick, setCouldClick] = useState(true);
  const [htmlPayment, setHtmlPayment] = useState("");
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // check login or not
    const token = localStorage.getItem("token");
    // console.log("token:::", token);
    if (token) {
      setIsLogin(true); // 提早先 true
      // call api check token available or not
      checkTokenAvailable(token);
    }
  }, []);

  const checkTokenAvailable = async (token) => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${token}`,
        },
      };
      const res = await fetch(`${baseUrl}/account`, options);
      if (res.status === 401) {
        localStorage.clear();
        setIsLogin(false);
      }
      const json = await res.json();
      // console.log("json checkTokenAvailable::::", json);

      if (json.data?.status) {
        if (json.data?.status !== AccountStatus.Active) {
          // Login again
          localStorage.clear();
          setIsLogin(false);
        } else {
          // localStorage.setItem("token", token);
          localStorage.setItem("email", json.data?.email);
          setTokenGet(token);
          setEmailGet(json.data?.email);
          setIsLogin(true);
        }
      }
    } catch (e) {
      console.error("e::::", e.message);
    }
  };

  const navToLogin = async () => {
    window.location.href = `${baseUrl}/auth`;
  };

  const navToConversation = () => {
    navigate(routeConfig.conversation);
  };

  const setData = useCallback(
    (e) => {
      const name = e.target.name;
      const val = e.target.value;
      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData]
  );

  const validateTitle = (value) => {
    if (!value) {
      return "請輸入兌換碼";
    }
    return "";
  };

  const validator = {
    title: validateTitle,
  };

  const validateForm = () => {
    const error = {};
    let hasError = false;
    Object.keys(formData).forEach((title) => {
      const val = formData[title];
      const errorMsg = validator[title](val);
      if (!hasError) {
        hasError = Boolean(errorMsg);
      }
      error[title] = errorMsg;
    });
    setFormDataError(error);

    return hasError;
  };

  const onSubmit = async () => {
    setCouldClick(false);
    const hasError = validateForm();
    if (hasError) {
      setIsSubmit(true);
      setCouldClick(true);
      return;
    }
    await submit(formData.title);

    setIsSubmit(false);
  };

  const submit = async (data) => {
    // console.log("tokenGet:::", tokenGet);

    // TODO: submit with loading, but back api really quick could not need

    // apply coupon api
    const dataGet = {
      code: data,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(dataGet),
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/account/coupon`, options);
    if (res.ok) {
      // console.log("coupon apply success!!!!!");
      Swal.fire({
        title: "Success!",
        text: "coupon apply success 😁",
        icon: "success",
        confirmButtonText: "Cool",
        confirmButtonColor: "#031888",
      });
    } else {
      if (res.status === 401) {
        // console.log("Login expired, plz login again, coupon apply fail!!!!!");
        // Login again
        localStorage.clear();
        setIsLogin(false);
      } else {
        // console.log("coupon apply fail!!!!!");
        const json = await res.json();
        Swal.fire({
          title: "Error!",
          text: json?.error || "coupon apply fail QQ",
          icon: "error",
          confirmButtonText: "Try again",
          confirmButtonColor: "#031888",
        });
      }
    }

    setFormData({ title: "" });
    setCouldClick(true);
  };

  const buyButton = async (productType) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };

    const resPayment = await fetch(
      `${baseUrl}/payment/spgateway?productType=${productType}`,
      options
    );

    let textPayment = await resPayment.text();
    // console.log("resPayment::", textPayment);
    // const newWindow = window.open("", "_blank", "width=800,height=600");
    // newWindow.document.write(textPayment);
    // newWindow.document.close();
    // textPayment = textPayment.replace(/(<? *script)/gi, '')
    // const scriptStringIndex = textPayment.indexOf('<script>')

    setHtmlPayment("<html><body>" + textPayment + "</body></html>");
    setPaymentModalShow(true);

    // window.open(textPayment)

    // const jsonPayment = await resPayment.json()

    // console.log('jsonPayment::',jsonPayment)
  };

  return (
    <>
      <TopNav blur={!isLogin} />
      <Wrap blur={!isLogin}>
        <PurchaseWrap>
          <div>
            <ListTitle>JCAI 課程方案</ListTitle>
            {/* <div dangerouslySetInnerHTML={{__html: htmlPayment}} >
            </div> */}
            {/* {htmlPayment} */}
            {/* <div id="spgateway"></div> */}
            {/* <div className="content" dangerouslySetInnerHTML={{__html: htmlPayment}}></div> */}
            <div
              style={{
                display: paymentModalShow ? "flex" : "none",
                position: "fixed",
                top: "70px",
                left: "10px",
                right: "10px",
                bottom: "20px",
                zIndex: 99,
                justifyContent: "center",
                flexDirection: "column",
                backgroundColor: "white",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => setPaymentModalShow(false)}
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: "flex-end",
                  border: "1px solid black",
                  padding: "4px",
                }}
              >
                關閉付款頁
              </div>
              <iframe
                title="My HTML"
                srcDoc={htmlPayment}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <ClassOptionWrap>
            <ClassOption main={false} >
              <ClassTitle style={{color:'gray'}}>1堂課</ClassTitle>
              <ClassDescription>只想上上試聽課</ClassDescription>
              <ClassPriceWrap>
                <PriceSymbol>$</PriceSymbol>
                <PriceDollar>59</PriceDollar>
                <PriceNumber>/1堂課</PriceNumber>
              </ClassPriceWrap>

              <ButtonPurchase
                type="button"
                main={false}
                disabled
                onClick={() => buyButton("One15MinClass")}
              >
                購買1堂課 $59
              </ButtonPurchase>
              <ClassPriceNote>
                一堂課為15分鐘，使用期限為購買日起1個月
              </ClassPriceNote>
            </ClassOption>
            <ClassOption main={true}>
              <img alt="shield-star" src={"../assets/images/shield-star.svg"} />
              <ClassTitle>10堂課</ClassTitle>
              <ClassDescription>快速進步英文聽說</ClassDescription>
              <ClassPriceWrap>
                <PriceSymbol>$</PriceSymbol>
                <PriceDollar><s style={{color:'gray'}}>35</s> 19</PriceDollar>
                <PriceNumber>/1堂課</PriceNumber>
              </ClassPriceWrap>

              <ButtonPurchase  main={true} onClick={() => buyButton("Ten15MinClass")}>
                購買10堂課  $190
              </ButtonPurchase>
              <ClassPriceNote>
                一堂課為15分鐘，使用期限為購買日起3個月
              </ClassPriceNote>
            </ClassOption>
            <ClassOption main={false}>
              <ClassTitle style={{color:'gray'}}>90堂課</ClassTitle>
              <ClassDescription>三個月流利練聽說</ClassDescription>
              <ClassPriceWrap>
                <PriceSymbol>$</PriceSymbol>
                <PriceDollar>29</PriceDollar>
                <PriceNumber>/1堂課</PriceNumber>
              </ClassPriceWrap>

              <ButtonPurchase main={false} disabled onClick={() => buyButton("Ninety15MinClass")}>
                購買90堂課 $2520
              </ButtonPurchase>
              <ClassPriceNote>
                一堂課為15分鐘，使用期限為購買日起6個月
              </ClassPriceNote>
            </ClassOption>
          </ClassOptionWrap>
          <FeatureList>
            <ListTitle>JCAI 優惠活動</ListTitle>
            <ListDescription>
              輸入兌換碼享課程優惠，免費體驗輕鬆自在與AI對談，英文會話輕鬆上手。
            </ListDescription>
            <SpecialTitle>兌換碼</SpecialTitle>
            <InputSection>
              <Field>
                <Input
                  placeholder={"chatgpt"}
                  name="title"
                  value={formData.title}
                  onChange={setData}
                  isError={formDataError.title}
                />
                {formDataError.title && (
                  <ErrorBlock>
                    <ErrorText>{formDataError.title}</ErrorText>
                  </ErrorBlock>
                )}
              </Field>
              <Submit onClick={couldClick ? onSubmit : null}>送出</Submit>
            </InputSection>
          </FeatureList>
        </PurchaseWrap>
        <Footer />
      </Wrap>
      {!isLogin && <Alert actionHandler={navToLogin} />}
    </>
  );
};

export default Purchase;
