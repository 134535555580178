import React,{useEffect} from "react";
import { Routes, Route, useParams } from "react-router-dom";
// import TopNav from "./common/components/TopNav";
import Home from "./pages/Home";
import Conversation from "./pages/Session/Conversation";
// import SessionList from "./pages/Session/SessionList";
import Purchase from "./pages/Purchase";
import Review from "./pages/Review";
import ReviewDetail from "./pages/Review/ReviewDetail";
import Privacy from "./pages/Rules/Privacy";
import UserRules from "./pages/Rules/UserRules";
import { routeConfig } from "./routes";




const App = () => {

  return (
    <main>
      {/* <TopNav blur={false} /> */}
      <Routes>
        <Route path={routeConfig.home} element={<Home />} />
        <Route path={routeConfig.conversation} element={<Conversation />} />
        <Route path={routeConfig.buyPlan} element={<Purchase />} />
        <Route path={routeConfig.sessionList} element={<Review />} />
        {/* <Route path={routeConfig.sessionReview} element={<ReviewDetail />}/> */}
        <Route path={routeConfig.sessionReview}>
          <Route path=":sessionId" element={<ReviewDetail />} />
        </Route>
        <Route path={routeConfig.privacy} element={<Privacy />} />
        <Route path={routeConfig.userRules} element={<UserRules />} />
      </Routes>
    </main>
  );
};

export default App;
