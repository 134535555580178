import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { forMobile } from "../../breakpoints";
import { MenuLinks } from "../../constants";
import { Icon } from "./styled";

const SLIDER_PERCENT = 50;

const SectionTitle = styled.div`
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 23.17px;
  color: #686868;

  &:hover {
    background-color: #efefef;
    color: #191919;
  }
  &:active {
    background-color: #efefef;
    color: #191919;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 57;
  left: 0;
  z-index: 1000;

  ${(props) =>
    props.isShowMenu &&
    css`
      width: 100vw;
      max-width: 100%;
      height: 100vh;
      max-height: 100%;
      overflow: hidden;
    `}
  ${(props) =>
    props.theme.isMember &&
    css`
      overflow: hidden;
    `}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Menu = styled.div`
  padding: 16px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 240px; // ${SLIDER_PERCENT}%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 15px 30px rgba(82, 79, 79, 0.1);
  ${(props) =>
    props.isShowSelector &&
    css`
      left: ${SLIDER_PERCENT - 100}%;
    `}
  transition: left .5s;
`;

const showMenuStyle = css`
  left: 240px; //${SLIDER_PERCENT}%;
  height: 100%;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    cursor: pointer;
  }
`;

const Content = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); //#ffffff;
  ${(props) => props.isShowMenu && showMenuStyle}
  transition: left .5s;
  ${(props) =>
    props.hideOverflowX &&
    css`
      ${forMobile} {
        overflow-x: hidden;
      }
    `}
  ${(props) =>
    props.theme.isMember &&
    css`
      min-height: 100vh;
      background-color: #f5f5f5;
    `}
`;

const BurgerMenu = ({ children, hideOverflowX }) => {
  const [isShowMenu, handleShowMenu] = useState(false);
  const [showSelector, handleShowSelector] = useState(null);

  return (
    <Wrapper isShowMenu={isShowMenu}>
      <Background>
        <Menu isShowSelector={!!showSelector}>
          {MenuLinks.map((option, idx) => (
            <a href={option.url}>
              <SectionTitle key={idx}>
                <Icon
                  src={`../assets/images/${option.icon}.png`}
                  alt="menu icon"
                />
                {option.key}
              </SectionTitle>
            </a>
          ))}
        </Menu>
      </Background>
      <Content
        isShowMenu={isShowMenu}
        hideOverflowX={hideOverflowX}
        onClick={() => (isShowMenu ? handleShowMenu(false) : null)}
      >
        {children({ handleShowMenu })}
      </Content>
    </Wrapper>
  );
};

BurgerMenu.propTypes = {
  match: PropTypes.shape({}),
  staticContext: PropTypes.shape({}),
  hideOverflowX: PropTypes.bool,
};

BurgerMenu.defaultProps = {
  hideOverflowX: true,
};

export default BurgerMenu;
