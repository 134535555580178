import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TopNav from "../../common/components/TopNav";
import {
  SessionListWrap,
  SessionWrap,
  Wrap,
  Tag,
  PhraseSession,
  PhraseSessionWrap,
  Button,
  ClassTitle,
  EmptyWrap,
  EmptyPage,
  Doll,
  StartText,
} from "./styled";
import { baseUrl, routeConfig } from "../../routes";

const Review = () => {
  const [sessionList, setSessionList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // check login or not
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(routeConfig.home);
    } else {
      //   setTokenGet(token);
      getSessions(token);
    }
  }, [navigate]);

  const getSessions = async (tokenGet) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/learning-session`, options);
    const allSession = await res.json();
    // console.log("all Session::::", allSession);

    if (res.ok && allSession.data?.length > 0) {
      setSessionList(allSession.data.reverse());
    } else {
      if (res.status === 401) {
        // console.log("Login expired, plz login again, review get session fail!!!!!" );
        // Login again
        localStorage.clear();
        navigate(routeConfig.home);
      } else {
        if (!res.ok) {
          // console.log("review get session fail!!!!!");
          const json = await res.json();
          Swal.fire({
            title: "Error!",
            text: json?.error || "review get session fail QQ",
            icon: "error",
            confirmButtonText: "Try again",
            confirmButtonColor: "#031888",
          });
        }
      }
    }
  };

  // useEffect(()=>{
  // eslint-disable-next-line no-extend-native
  Date.prototype.Format = function (fmt) {
    // author: meizz
    var o = {
      "M+": this.getMonth() + 1, // 月份
      "d+": this.getDate(), // 日
      "h+": this.getHours(), // 小時
      "m+": this.getMinutes(), // 分
      "s+": this.getSeconds(), // 秒
      "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
      S: this.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  };

  const strToarr = function (str) {
    const regex = /\d+\.\s+/g;
    const arr = str.split(regex).slice(1);
    return arr;
  };
  // },[])

  return (
    <>
      <TopNav />
      <Wrap height={sessionList.length === 0}>
        <ClassTitle>
          <div>學習總覽</div>
        </ClassTitle>

        <SessionListWrap>
          {sessionList.length > 0 &&
            sessionList.map((session, i) => {
              return (
                <SessionWrap key={i}>
                  {session.source === 0 ? <Tag>體驗課</Tag> : <Tag>正式課</Tag>}
                  <div style={{ display: "flex", marginBottom: "16px" }}>
                    <img alt="time" src={"../assets/images/time_icon.svg"} />
                    <p style={{ marginLeft: "8px" }}>
                      {new Date(session.startTime).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      )}
                    </p>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                      <img
                        alt="summarize"
                        src={"../assets/images/summarize_icon.svg"}
                      />
                      <p style={{ marginLeft: "8px" }}>課程總結</p>
                    </div>
                    <div style={{ height: "126px", overflow: "scroll" }}>
                      {session.summary || "no summary"}
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                      <img
                        alt="phrase"
                        src={"../assets/images/phrase_icon.svg"}
                      />
                      <p style={{ marginLeft: "8px" }}>片語學習</p>
                    </div>
                    {session.phrases &&
                    session.phrases.indexOf("5. ") !== -1 ? (
                      <div style={{ height: "94px", overflow: "scroll" }}>
                        {strToarr(session.phrases).map((phrase) => {
                          return (
                            <div
                              style={{
                                display: "inline-block",
                                backgroundColor: "rgba(170,182,253,0.2)",
                                padding: "2px 10px",
                                margin: "2px",
                                fontSize: "15px",
                                borderRadius: "5px",
                              }}
                            >
                              {phrase}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <PhraseSessionWrap phrases={session.phrases}>
                        <PhraseSession>
                          {session.phrases || "no phrases"}
                        </PhraseSession>
                      </PhraseSessionWrap>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    <Button
                      href={`${routeConfig.sessionReview}/${session._id}`}
                    >
                      前往課程複習
                    </Button>
                  </div>
                </SessionWrap>
              );
            })}
        </SessionListWrap>

        {sessionList.length === 0 && (
          <EmptyWrap>
            <EmptyPage>
              <Doll
                src="../assets/images/woman_coffee.svg"
                alt="man_taking_photo image"
              />
              <StartText>您還沒有任何課程歷史紀錄</StartText>
            </EmptyPage>
          </EmptyWrap>
        )}
      </Wrap>
    </>
  );
};

export default Review;
