import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Overlay, OverlayBackground, Wrap } from "../styled";
import { routeConfig } from "../../../../routes";
import {
  Title,
  Description,
  BellIcon,
  Row,
  Button,
  ButtonTitle,
  ListWrap,
} from "./styled";

const StartAlert = ({ actionHandler }) => {
  const navigate = useNavigate();
  const navToHome = () => {
    navigate(routeConfig.home);
  };
  const classGet = JSON.parse(localStorage.getItem("classAll"));

  const [disable, setDisable] = useState(false);

  return (
    <Overlay>
      <OverlayBackground isTransparentBackground={true}>
        <Wrap>
          <BellIcon
            src="../assets/images/back_Icon.png"
            alt="back Icon"
            onClick={navToHome}
          />

          <Title>
            {disable
              ? "忙碌中"
              : classGet?.length === 0
              ? "您沒有任何課程可以使用"
              : "選擇課程"}
          </Title>
          {disable ? (
            <Description>流量目前爆表，無法上課😭</Description>
          ) : classGet?.length === 0 ? (
            <Description>
              請至首頁購買課程或兌換體驗課，再進入課堂！😊
            </Description>
          ) : (
            <>
              <Description> 準備好開始上課了嗎？</Description>
              <Description last>請選擇下方您要抵用的課程</Description>
              {/* <Row>
            <Button dark onClick={navToHome}>
              <ButtonTitle dark>再等等</ButtonTitle>
            </Button>
            <Button onClick={actionHandler}>
              <ButtonTitle>開始上課</ButtonTitle>
            </Button>
          </Row> */}
              <ListWrap>
                {classGet?.length > 0 &&
                  classGet.map((type, idx) => {
                    return (
                      <Button
                        last={idx === classGet.length - 1}
                        onClick={async () => await actionHandler(type)}
                      >
                        <Row>
                          <ButtonTitle>
                            {type.type === "Coupon" ? "體驗課" : "正式課"} (
                            {type.type === "Coupon" ? "5" : "15"} 分鐘)
                          </ButtonTitle>
                          <ButtonTitle end>剩餘 {type.count} 堂</ButtonTitle>
                        </Row>
                      </Button>
                    );
                  })}
              </ListWrap>
            </>
          )}
        </Wrap>
      </OverlayBackground>
    </Overlay>
  );
};

export default StartAlert;
