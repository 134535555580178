import styled from "styled-components";

export const HeadTitle = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #282828;
  margin-bottom: 8px;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: #191919;
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #191919;
`;

export const Divider = styled.div`
  border: 1px solid #e0e0e0;
`;

export const ReviewIcon = styled.img`
  margin-right: 8px;
  width: 18px;
  height: 18px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: center;
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #191919;
  ${(props) =>
    props.loading &&
    `
  animation: pulse 1s infinite;
  `}
`;

export const Phrases = styled(Description)`
  font-weight: 500;
`;

export const PhrasesWrap = styled.div`
  padding: 4px 8px;
  background: rgba(170, 182, 253, 0.2);
  border-radius: 5px;
  margin-bottom: 24px;
`;
export const Button = styled.div`
  display: flex;
  flex: 0.48;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  cursor: pointer;
  ${(props) => props.dark && `background-color: #031888`}
`;

export const ButtonTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.dark ? "#FFFFFF" : "#031888")};
  display: flex;
`;
