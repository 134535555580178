import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../routes";
import { MenuLinks } from "../../constants";
import { forMobile, greaterThanMobile } from "../../breakpoints";

const Wrap = styled.div`
  width: 100vw;
  background-color: #031888;
  justify-content: center;
  align-items: center;
  display: flex;

  ${forMobile} {
    background-color: #031888;
    flex-direction: column;
  }
`;

const TotalWrap = styled.div`
  max-width: 1120px;
  padding: 56px 16px 40px;

  ${forMobile} {
    padding: 56px 16px 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.last && `margin-top:8px;`}
  ${forMobile} {
    margin: 0;
    flex-direction: column;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${forMobile} {
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  // font-family: "Poppins";
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 8px;
  ${forMobile} {
    font-size: 16px;
  }
`;

const LogoIcon = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const DescriptionSmall = styled.p`
  // font-weight: 500;
  font-size: 14px;
  line-height: 20.27px;
  color: #ffffff;
  width: 37vw;

  ${forMobile} {
    text-align: center;
    font-size: 9px;
    line-height: 13.03px;
  }
`;

const Description = styled.p`
  // font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  text-align: right;

  ${forMobile} {
    text-align: center;
  }
`;

const FeatureWrap = styled.div`
  margin: 0px;
  padding: 16px 0 40px;
  width: 37vw;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;

  ${forMobile} {
    margin: 8px 0px;
    padding: 16px 0 40px;
    width: 37vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

const Feature = styled.p`
  // font-weight: 500;
  font-size: 14px;
  line-height: 20.27px;
  color: #ffffff;
  text-align: center;
  margin-right: 24px;
  ${(props) => props.right && "margin-right: 0px;"}

  ${forMobile} {
    font-size: 12px;
    line-height: 17.38px;
    margin-right: 0px;
    margin-bottom: ${(props) => (props.last ? "0" : "12px")};
  }
`;

const LogoListWrap = styled.div`
  margin: 8px 0 0;
  align-items: center;
  display: flex;
  flex: 1;

  ${forMobile} {
    margin: 8px 0;
    justify-content: center;
  }
`;

const SocialLogo = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 6px;
  ${(props) => props.first && `margin-left: 0;`}
  ${forMobile} {
    width: 18px;
    height: 18px;
    margin: 0 4px;
  }
`;

const WrapTag = styled.div`
  justify-content: flex-end;
  align-items: center;
  ${greaterThanMobile} {
    width: 37vw;
  }
`;

const DescriptionTag = styled.p`
  // font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  color: #ffffff;
  text-align: right;
  margin-top: 8px;

  ${forMobile} {
    text-align: center;
  }
`;

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Wrap>
      <TotalWrap>
        <Block>
          <LogoWrap>
            <Title>
              <LogoIcon
                src="../assets/images/logo_dark.png"
                alt="logo dark image"
              />
              JCAI
            </Title>
            <DescriptionSmall>
              與AI聊天學英文- 3個月讓你流利聽說英文
            </DescriptionSmall>
          </LogoWrap>
          <FeatureWrap>
            {MenuLinks.map((option, idx) => (
              <a href={option.url}>
                <Feature> {option.key}</Feature>
              </a>
            ))}
            <Feature onClick={() => navigate(routeConfig.privacy)}>
              隱私權政策
            </Feature>
            <Feature last right onClick={() => navigate(routeConfig.userRules)}>
              使用者條款
            </Feature>
          </FeatureWrap>
        </Block>
        <Block last>
          <LogoListWrap>
            <a href="https://www.facebook.com/jcaitutor">
              <SocialLogo
                first
                src="../assets/images/fb_logo.png"
                alt="fb logo image"
              />
            </a>
            <a href="mailto: jcaitutor@gmail.com">
              <SocialLogo src="../assets/images/email.svg" alt="email svg" />
            </a>
            {/* <SocialLogo
              src="../assets/images/ig_logo.png"
              alt="lig ogo image"
            />
            <SocialLogo
              src="../assets/images/twitter_logo.png"
              alt="twitter logo image"
            /> */}
          </LogoListWrap>
          <WrapTag>
            <Description>© 2023 JCAI, Inc. All rights reserved.</Description>
            <DescriptionTag>Illustration by Icons 8 from Ouch!</DescriptionTag>
          </WrapTag>
        </Block>
      </TotalWrap>
    </Wrap>
  );
};

export default Footer;
