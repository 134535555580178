import styled from "styled-components";
import { greaterThanMobile } from "../../../common/breakpoints";

export const Wrapper = styled.div`
  ${(props) =>
    props.blur &&
    `
  filter: blur(3px);
  -webkit-filter: blur(3px);
  `}
`;

export const Wrap = styled.div`
  background: rgba(96, 150, 253, 0.05);
  padding-top: 246px; //206px;
  padding-bottom: 135px;

  ${greaterThanMobile} {
    padding-top: 276px;
  }
`;

export const ConversationW = styled.div`
  //   background: rgba(96, 150, 253, 0.05);
  // padding: 222px 16px 0px; // 580px;;
  padding: 222px 16px 110px;
`;

export const DialogWrap = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;

export const FixTop = styled.div`
  margin-top: 50px;
  position: fixed;
  width: 100%;
  top: 58;
  left: 0;
  z-index: 999;
`;

export const Timer = styled.p`
  background-color: ${(props) => (props.isFinish ? "#FB7B8E" : "#6096fd")};
  height: 36px;
  padding: 8px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-items: center;
  color: #ffffff;
`;

export const Time = styled.p`
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
`;

export const TopWrap = styled.div`
  padding: 16px;
  background-color: #ffffff;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #191919;
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.div`
  display: flex;
  flex: 0.4888;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  cursor: pointer;
  ${(props) => props.dark && `background-color: #031888`}
`;

export const ButtonIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

export const ButtonTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.dark ? "#FFFFFF" : "#031888")};
`;

export const Divider = styled.div`
  border: 1px solid #e0e0e0;
`;

export const EmptyPage = styled.div`
  padding: 93px 85px 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Doll = styled.img`
  margin-bottom: 38px;
  width: 205px;
  height: 260px;
`;

export const StartText = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #6096fd;
`;

export const FixBottom = styled.div`
  justify-content: center;
  padding: 0 16px;
  flex-direction: column;
  display: flex;
  max-width: 1120px;
  margin: 0 auto;

  //   background: rgba(96, 150, 253, 0.05);

  position: fixed;
  width: 100%;
  bottom: 38px;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const TalkPress = styled.div`
  width: 72px;
  height: 72px;
  background: #ffffff;
  box-shadow: inset 0px -4px 7px rgba(164, 164, 164, 0.25),
    inset 2px 4px 7px rgba(164, 164, 164, 0.25);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PhoneIcon = styled.img`
  width: 39px;
  height: 39px;
`;

export const HolePage = styled.div`
  padding: 0 16px;
`;

export const RecordWrap = styled.div`
  padding: 16px;
  background: linear-gradient(
      0deg,
      rgba(202, 220, 254, 0.6),
      rgba(202, 220, 254, 0.6)
    ),
    #ffffff;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);
  border-radius: 12px 12px 2px 12px;
  margin-bottom: 32px;
`;

export const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export const RecordIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

export const RecordText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #191919;
`;

export const TalkWrap = styled.div`
  display: flex;
  justify-content: center;
  //   background-color: red;
`;

export const BottomFix = styled.div`
  background-color: rgba(96, 150, 253, 0.05);
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 74px;
`;

export const RowTalk = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const UserTalk = styled.div`
  width: 288px;
  padding: 16px;
  background: #cadcfe;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);
  border-radius: 12px 12px 2px 12px;
  margin-bottom: 24px;
`;

export const AITalk = styled(UserTalk)`
  background: #ffffff;
  border-radius: 12px 12px 12px 2px;
  ${(props) =>
    props.loading &&
    `
  animation: pulse 1s infinite;
  background-color: gray;`}
`;

export const Talk = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => (props.loading ? "#FFFFFF" : "#191919")};
  margin-bottom: ${(props) => (props.loading ? 0 : "16px")};
`;

export const AITitle = styled(Talk)`
  font-weight: 600;
`;

export const IconWrap = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const MagicIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  ${(props) => props.marginRight && `margin-right:12px`}
  ${(props) => props.marginTitle && `margin-right:4px`}
   ${(props) =>
    props.loading &&
    `
  animation: pulse 1s infinite;
  `}
`;

export const Revise = styled.div`
  padding: 16px;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #aab6fd;
  border-radius: 12px;
`;

export const ReviseTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

export const LoadingWrap = styled.div`
  padding: 0 16px 110px;
  margin-top: -110px;
`;
