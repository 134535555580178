import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../routes";
import { AccountStatus } from "../../common/constants";
import TopNav from "../../common/components/TopNav";
import { Wrap, TotalWrap, TopWrap, Title } from "../Home/styled";

const UserRules = () => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // check login or not
    const token = localStorage.getItem("token");
    if (token) {
      setIsLogin(true); // 提早先 true
      // call api check token available or not
      checkTokenAvailable(token);
    }
  }, [navigate]);

  const checkTokenAvailable = async (token) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(`${baseUrl}/account`, options);
    const json = await res.json();
    // console.log("json checkTokenAvailable::::", json);

    if (json.data?.status) {
      if (json.data?.status !== AccountStatus.Active) {
        // Login again
        localStorage.clear();
        setIsLogin(false);
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("email", json.data?.email);
        setIsLogin(true);
      }
    }
  };
  return (
    <>
      <TopNav menuLogin={!isLogin} />
      <Wrap>
        <TotalWrap>
          <TopWrap>
            <Title>使用者條款</Title>
            <div
              class="col-md-10 col-md-offset-1"
              style={{ marginTop: "30px" }}
            >
              <div class="pad-t-30">
                <h1>JCAI 使用者條款</h1>
                <p>
                  本使用者條款（以下稱「本條款」）適用於所有位於及連結於 JCAI
                  網域之網站。
                </p>
                <h2 class="heading">
                  <strong>同意條款</strong>
                </h2>
                <p>
                  不論個別的使用者其物理位置於何處，本條款適用於所有連結至 JCAI
                  之會員或訪客（以下合稱「使用者」、「您」），透過持續使用 JCAI
                  提供之服務，即表示您已知悉且同意本條款，若您不同意本條款之部分或全部內容，您應立即停止使用
                  JCAI。
                </p>
                <p>
                  若您有任何疑問，歡迎隨時直接聯繫我們的客服人員
                  jcaitutor@gmail.com。
                </p>
                <p>
                  使用者未滿七歲，不得於 JCAI 「建立帳號」
                  <b>（如涉及購買等事項）</b>
                  ，須由其法定代理人建立帳號。如使用者已滿七歲未滿二十歲，則其申請註冊成為會員前，需得其法定代理人同意，始得註冊及使用
                  JCAI
                  所提供的會員服務。使用者未滿二十歲，須保證其獲得法定代理人同意可使用
                  JCAI 提供的服務。當該使用者申請註冊成為會員，並繼續使用 JCAI
                  提供的服務時，則表示其法定代理人已經閱讀且知悉並同意本條款。
                </p>
                <h2 class="heading">
                  <strong>本條款更新</strong>
                </h2>
                <p>
                  JCAI
                  在法律准許的範圍內，有權隨時修改、調整本條款中的任何條款內容。一旦
                  JCAI 對本條款內容進行修改或調整，JCAI
                  將會以公告形式發佈通知。任何修改或調整將於公布之時起生效。如果使用者在修改公布後，繼續使用
                  JCAI
                  所提供的服務，則表示同意遵守本條款之更動。因此，請使用者務必定期查閱本條款，以確保了解本條款的所有更動。如果使用者不同意
                  JCAI 對本條款之更動，請離開 JCAI 網站並立即停止使用 JCAI
                  服務，同時，會員應刪除個人檔案並註銷會員資格。JCAI
                  集團亦有權於下列違規情事發生時，刪除會員個人檔案並註銷會員資格。
                </p>
                <h2 class="heading">
                  <strong>會員帳號及密碼安全</strong>
                </h2>
                <p>
                  註冊成為會員之使用者享受 JCAI 提供的各項服務。訪客可以很容易在
                  JCAI 上建立帳號，只要使用其個人已註冊之 Google 帳號登入即可。
                </p>
                <p>
                  使用者不應使用任何與其個人真實資料無關之資訊建立其帳號名稱，包括但不限於使用模仿他人姓名或使用攻擊性的字眼、任何種族歧視的文字及任何會傷害、侵害他人權利之文字作為帳號名稱，如發生前開事項，JCAI
                  有權終止該帳號的服務，應注意如下事項：會員應妥善保管其帳號及密碼，不應將其帳號及密碼轉讓或出借給他人使用，如會員發現其帳號遭他人非法使用，應立即通知
                  JCAI。因駭客行為或會員的保管疏忽導致帳號、密碼遭他人非法使用，JCAI
                  不負擔任何責任。
                </p>
                <p>
                  您有義務妥善保管帳戶、密碼及其他相關身分驗證資訊，並為此帳號登入系統後所進行之一切活動、行為負責，包括但不限於衍生之相關費用。
                </p>
                <p>
                  您同意在使用 JCAI
                  時遵循所有適用法律，且僅可基於合法目的使用本服務。以非經 JCAI
                  同意或指示之方式使用本服務或接觸系統，均應被嚴格禁止，JCAI
                  有權停止提供該使用者之服務。
                </p>
                <h2 class="heading">
                  <strong>付款與退費</strong>
                </h2>
                <p>
                  付款後，服務有一定使用時間，逾期不予退費，但若為特殊情況，可經由客服協助。
                </p>
                <p>
                  若購買後7天內，若無使用服務，扣除金流手續費，即可全額退費，可經由客服協助。
                </p>
                <p>客服信箱為：jcaitutor@gmail.com</p>
                <h2 class="heading">
                  <strong>一般性條款</strong>
                </h2>
                <p>
                  使用此服務，不得使用其後端api去執行其他不在本網站服務之內容，違者須支付10倍api使用費。
                </p>
                <p>
                  本條款及使用者與 JCAI
                  之關係，均以中華民國法律為準據法。使用者與 JCAI
                  就本服務、本條款或其它有關事項發生的爭議，應以台灣台北地方法院為第一審管轄法院。倘本條款之部分規定因與中華民國法律抵觸而無效者，本條款其它規定仍應具有完整的效力及效果。本條款之標題僅供方便而設，不具任何法律或契約效果。
                </p>
                <h2 class="heading">
                  <strong>檢舉</strong>
                </h2>
                <p>
                  倘發現任何違反本條款之情事，請以 Email 寄至{" "}
                  <a href="mailto:jcaitutor@gmail.com">jcaitutor@gmail.com</a>{" "}
                  通知 JCAI。
                </p>
                <h2 class="heading">
                  <strong>聯絡我們</strong>
                </h2>
                <p>
                  如果對本服務條款或我們的信息處理方法有任何疑問，請通過電子郵件的方式聯繫：
                  <a href="mailto:jcaitutor@gmail.com">jcaitutor@gmail.com</a>。
                </p>
              </div>
            </div>
          </TopWrap>
        </TotalWrap>
      </Wrap>
    </>
  );
};

export default UserRules;
