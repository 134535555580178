import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from "query-string";

import TopNav from "../../common/components/TopNav";
import Alert from "../../common/components/Alert/LoginAlert";
import Footer from "../../common/components/Footer";
import { baseUrl, routeConfig } from "../../routes";
import { AccountStatus, START_ENCOURAGEMENT } from "../../common/constants";
import {
  Wrap,
  TotalWrap,
  TopWrap,
  Title,
  Email,
  FeatureWrap,
  FeatureWrapMore,
  FeatureWrapLeft,
  LeftInfo,
  DollRightBlank,
  DollRightBlankLeft,
  DollRight,
  DollRightest,
  DollLeft,
  FeatureTitle,
  TitleDescription,
  ButtonRight,
  Button,
  ButtonIcon,
  ButtonTitle,
  FeatureList,
  ListTitle,
  ListDescription,
  Row,
  Item,
  ItemImag,
  ItemTitle,
  SpecialTitle,
  Input,
  ErrorBlock,
  ErrorText,
  InputSection,
  Submit,
  Field,
  MiddleWrap,
} from "./styled";

const Home = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [tokenGet, setTokenGet] = useState(null);
  const [emailGet, setEmailGet] = useState(null);
  const [totalDurationGet, setTotalDurationGet] = useState(0);
  const [formDataError, setFormDataError] = useState({ title: "" });
  const [formData, setFormData] = useState({ title: "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const [couldClick, setCouldClick] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Test flow to clear all
    // localStorage.clear();

    // if login success, split url to get token
    let value = "";
    let url = window.location.href;

    // console.log("url:", url);
    const { token } = queryString.parse(window.location.search);
    if (token) {
      // value = url.split("=")[1];
      value = token;
      // setIsLogin(true);
      // console.log("after login get token:::", value);
      // console.log(value[value.length - 1]);
      if (value[value.length - 1] === "#") {
        value = value.slice(0, value.length - 1);
      }
      // console.log(value);
      localStorage.setItem("token", value);

      window.location.replace(`${process.env.REACT_APP_URL}`);
    }
  }, []);

  useEffect(() => {
    // console.log("api:", process.env.REACT_APP_API_URL);

    // check login or not
    const token = localStorage.getItem("token");
    // console.log("token:::", token);
    if (token) {
      setIsLogin(true); // 提早先 true
      // call api check token available or not
      checkTokenAvailable(token);
    }
  }, []);

  const checkTokenAvailable = async (token) => {
    try {
      setLoading(true);
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${token}`,
        },
      };
      const res = await fetch(`${baseUrl}/account`, options);
      if (res.status === 401) {
        // console.log("401 error");
        localStorage.clear();
        setIsLogin(false);
        setLoading(false);
      } else {
        const json = await res.json();
        // console.log("json checkTokenAvailable::::", json);

        if (json.data?.status) {
          if (json.data?.status !== AccountStatus.Active) {
            // Login again
            // console.log("帳號status有誤");
            localStorage.clear();
            setIsLogin(false);
          } else {
            // localStorage.setItem("token", token);
            localStorage.setItem("email", json.data?.email);
            setTokenGet(token);
            setEmailGet(json.data?.email);
            setIsLogin(true);
            getSessions(token);
            setLoading(false);
          }
        } else {
          localStorage.clear();
          setIsLogin(false);
        }
      }
    } catch (e) {
      console.error("e::::", e.message);
    }
  };

  const getSessions = async (tokenGet) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/learning-session`, options);
    const allSession = await res.json();
    // console.log("all Session::::", allSession);

    if (res.ok && allSession.data?.length > 0) {
      const sumWithInitial = allSession.data.reduce(
        (accumulator, currentValue) =>
          accumulator + (currentValue["duration"] || 0),
        0
      );

      setTotalDurationGet(Math.floor(sumWithInitial / 60));
    } else {
      if (res.status === 401) {
        // console.log( "Login expired, plz login again, review get session fail!!!!!" );
        // Login again
        localStorage.clear();
        setIsLogin(false);
      } else {
        if (!res.ok) {
          // console.log("review get session fail!!!!!");
          const json = await res.json();
          Swal.fire({
            title: "Error!",
            text: json?.error || "review get session fail QQ",
            icon: "error",
            confirmButtonText: "Try again",
            confirmButtonColor: "#031888",
          });
        }
      }
    }
  };

  const navToLogin = async () => {
    window.location.href = `${baseUrl}/auth`;
  };

  const navToConversation = () => {
    navigate(routeConfig.conversation);
  };

  const setData = useCallback(
    (e) => {
      const name = e.target.name;
      const val = e.target.value;
      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData]
  );

  const validateTitle = (value) => {
    if (!value) {
      return "請輸入兌換碼";
    }
    return "";
  };

  const validator = {
    title: validateTitle,
  };

  const validateForm = () => {
    const error = {};
    let hasError = false;
    Object.keys(formData).forEach((title) => {
      const val = formData[title];
      const errorMsg = validator[title](val);
      if (!hasError) {
        hasError = Boolean(errorMsg);
      }
      error[title] = errorMsg;
    });
    setFormDataError(error);

    return hasError;
  };

  const onSubmit = async () => {
    setCouldClick(false);
    const hasError = validateForm();
    if (hasError) {
      setIsSubmit(true);
      setCouldClick(true);
      return;
    }
    await submit(formData.title);

    setIsSubmit(false);
  };

  const submit = async (data) => {
    // console.log("tokenGet:::", tokenGet);

    // TODO: submit with loading, but back api really quick could not need

    // apply coupon api
    const dataGet = {
      code: data,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(dataGet),
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/account/coupon`, options);
    if (res.ok) {
      // console.log("coupon apply success!");
      Swal.fire({
        title: "Success!",
        text: "體驗課兌換成功，請按開始課程😁",
        icon: "success",
        confirmButtonText: "Cool",
        confirmButtonColor: "#031888",
      });
    } else {
      if (res.status === 401) {
        // console.log("Login expired, plz login again, coupon apply fail!!!!!");
        // Login again
        localStorage.clear();
        setIsLogin(false);
      } else {
        // console.log("coupon apply fail!");
        const json = await res.json();
        // console.log("coupon error::::", json?.error);
        Swal.fire({
          title: "Error!",
          text: json?.error || "coupon apply fail QQ",
          icon: "error",
          confirmButtonText: "Try again",
          confirmButtonColor: "#031888",
        });
      }
    }

    setFormData({ title: "" });
    setCouldClick(true);
  };

  const startSession = async () => {
    // console.log("startSession:::::::");
    setCouldClick(false);
    // get account available classes api
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${tokenGet}`,
      },
    };
    const res = await fetch(`${baseUrl}/account/available-class`, options);
    const json = await res.json();
    // console.log("json class type::::", json);

    if (res.ok) {
      // console.log("準備開啟對話！！！");

      const classAllGet = json.data;
      localStorage.setItem("classAll", JSON.stringify(classAllGet));

      navToConversation();
    } else {
      if (res.status === 401) {
        // Login again
        // console.log("Login expired, plz login again, coupon apply fail!!!!!");
        localStorage.clear();
        setIsLogin(false);
      } else {
        // console.log("startSession fail!!!!!");
        const json = await res.json();
        Swal.fire({
          title: "Error!",
          text: json?.error || "start session fail QQ",
          icon: "error",
          confirmButtonText: "Try again",
          confirmButtonColor: "#031888",
        });
      }
    }

    setCouldClick(true);
  };

  return (
    <>
      <TopNav blur={!isLogin} />
      <Wrap blur={!isLogin || loading}>
        <TotalWrap>
          <TopWrap>
            <Title>Hello,</Title>
            <Email>{emailGet}</Email>
          </TopWrap>
          <FeatureWrap background="rgba(96, 150, 253, 0.3)">
            <LeftInfo>
              <FeatureTitle>嗨! JCAI 等不及與您對話。</FeatureTitle>
              <TitleDescription>
                歡迎您加入JCAI，學習無需預約等待，即時無壓力的擬真人對談，潛移默化的培養自然與流利的口說，不再擔心害怕開口說英文。
              </TitleDescription>
              <Button onClick={couldClick ? startSession : null}>
                <ButtonIcon
                  src="../assets/images/dialog_white_Icon.png"
                  alt="dialog_white_Icon image"
                />
                <ButtonTitle>立即開始</ButtonTitle>
              </Button>
            </LeftInfo>
            <DollRightBlank />
            <DollRight
              src="../assets/images/woman_talking_online.png"
              alt="woman_talking_online image"
            />
            <DollRightest
              src="../assets/images/girl_idea.png"
              alt="girl_idea image"
            />
          </FeatureWrap>
          <MiddleWrap>
            <FeatureWrapLeft background="#FFFFFF">
              <DollRightBlankLeft />
              <DollLeft
                src="../assets/images/man_with_laptop_all.png"
                alt="man_with_laptop_all image"
              />
              <LeftInfo>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#191919",
                  }}
                >
                  學習總分鐘數
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "64px",
                    lineHeight: "96px",
                    color: "#191919",
                    textAlign: "right",
                  }}
                >
                  {totalDurationGet}
                </p>
              </LeftInfo>
            </FeatureWrapLeft>
            <FeatureWrapMore background="#FFFFFF">
              <LeftInfo>
                <FeatureTitle right>
                  開口說英文！人都怕自己太優秀。
                </FeatureTitle>
                <TitleDescription right>
                  {START_ENCOURAGEMENT[Math.floor(Math.random() * 4)]}
                </TitleDescription>
                <ButtonRight>
                  <Button onClick={couldClick ? startSession : null}>
                    <ButtonIcon
                      src="../assets/images/dialog_white_Icon.png"
                      alt="dialog_white_Icon image"
                    />
                    <ButtonTitle>立即開始</ButtonTitle>
                  </Button>
                </ButtonRight>
              </LeftInfo>
            </FeatureWrapMore>
          </MiddleWrap>
          <FeatureList>
            <ListTitle>JCAI 服務</ListTitle>
            <Row>
              <Item href={routeConfig.sessionList}>
                <ItemImag
                  src="../assets/images/stack_books.png"
                  alt="stack_books image"
                />
                <ItemTitle>學習總覽</ItemTitle>
              </Item>
              <Item href={routeConfig.buyPlan}>
                <ItemImag src="../assets/images/gift.png" alt="gift image" />
                <ItemTitle>兌換/購買課程</ItemTitle>
              </Item>
            </Row>
          </FeatureList>
          <FeatureList>
            <ListTitle>JCAI 優惠活動</ListTitle>
            <ListDescription>
              輸入兌換碼享課程優惠，免費體驗輕鬆自在與AI對談，英文會話輕鬆上手。
            </ListDescription>
            <SpecialTitle>兌換碼</SpecialTitle>
            <InputSection>
              <Field>
                <Input
                  placeholder={"chatgpt"}
                  name="title"
                  value={formData.title}
                  onChange={setData}
                  isError={formDataError.title}
                />
                {formDataError.title && (
                  <ErrorBlock>
                    <ErrorText>{formDataError.title}</ErrorText>
                  </ErrorBlock>
                )}
              </Field>
              <Submit onClick={couldClick ? onSubmit : null}>送出</Submit>
            </InputSection>
          </FeatureList>
        </TotalWrap>
        <Footer />
      </Wrap>
      {!isLogin && !loading && <Alert actionHandler={navToLogin} />}
    </>
  );
};

export default Home;
