import styled from "styled-components";
import { greaterThanMobile } from "../../breakpoints";

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 1020;
  overflow: hidden;

  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OverlayBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.isTransparentBackground ? "transparent" : "rgba(0, 0, 0, 0.5)"};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);
  border-radius: 10px;
  padding: 36px 24px;
  width: calc(100% - 32px);

  ${greaterThanMobile} {
    width: 480px;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: #191919;
`;

export const Subtitle = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: #6096fd;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const Description = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #031888;
  margin-bottom: 24px;
`;

export const Login = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #031888;
  cursor: pointer;
`;

export const LogoIcon = styled.img`
  margin-right: 8px;
  width: 31px;
  height: 31px;
`;

export const LoginIcon = styled.img`
  margin-right: 10px;
  width: 18px;
  height: 18px;
`;
