import styled from "styled-components";
import { forMobile, greaterThanMobile } from "../../common/breakpoints";

export const Wrap = styled.div`
  background: rgba(96, 150, 253, 0.05);
  padding-top: 50px;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.blur &&
    `
  filter: blur(3px);
  -webkit-filter: blur(3px);
  `}
`;

export const TotalWrap = styled.div`
  max-width: 1120px;
`;

export const TopWrap = styled.div`
  padding: 16px 32px 22px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #191919;
`;

export const Email = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #191919;
`;

export const FeatureWrap = styled.div`
  margin: 0 16px 31px;
  padding: 24px 32px;
  border-radius: 10px;
  background-color: ${(props) => props.background};
  flex-direction: row;
  display: flex;
  position: relative;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);

  ${forMobile} {
    margin: 0 16px 36px;
    padding: 16px 24px;
  }
`;

export const FeatureWrapLeft = styled(FeatureWrap)`
  flex: 1.3;
`;

export const FeatureWrapMore = styled(FeatureWrap)`
  flex: 2;
  ${forMobile} {
    display: none;
  }
`;

export const LeftInfo = styled.div`
  flex: 1;
`;

export const DollRightBlank = styled.div`
  width: 420px;
  margin-left: 34px;

  ${forMobile} {
    width: 123px;
    margin-left: 0px;
  }
`;

export const DollRightBlankLeft = styled(DollRightBlank)`
  width: 180px;
`;

export const DollRight = styled.img`
  width: 180px;
  position: absolute;
  top: -6px;
  right: 200px;
  z-index: 10;

  ${forMobile} {
    width: 137px;
    top: -6px;
    right: 10px;
  }
`;

export const DollRightest = styled.img`
  width: 266.98px;
  position: absolute;
  top: -6px;
  right: 0px;
  z-index: 9;

  ${forMobile} {
    width: 0px;
  }
`;

export const DollLeft = styled.img`
  width: 293px;
  position: absolute;
  top: 0px;
  left: -62px;
  z-index: 10;

  ${forMobile} {
    top: 26px;
    left: -107px;
  }
`;

export const FeatureTitle = styled.h1`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 36px;
  line-height: 52.13px;
  color: #191919;

  ${forMobile} {
    font-size: 24px;
    line-height: 35px;
  }

  ${(props) => props.right && `text-align:right;`}
`;

export const TitleDescription = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20.27px;
  color: #191919;

  ${forMobile} {
    margin-bottom: 0px;
    font-size: 0px;
    line-height: 0px;
  }

  ${(props) => props.right && `text-align:right;`}
`;

export const ButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background-color: #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  cursor: pointer;
  width: 112px;
`;

export const ButtonIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const ButtonTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export const FeatureList = styled.div`
  margin: 0px 16px 24px;
`;

export const ListTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  margin-bottom: 8px;

  ${forMobile} {
    font-size: 16px;
    line-height: 23px;
  }
`;

export const ListDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20.27px;
  color: #686868;
  margin-bottom: 12px;

  ${forMobile} {
    font-size: 12px;
    line-height: 17px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Item = styled.a`
  // width: 18.5vw; // 357.33px;

  flex: 0.4888;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.25);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  display: flex;
  cursor: pointer;

  ${forMobile} {
    // width: 109px; // 29vw;
  }
`;

export const ItemImag = styled.img`
  height: 36px; // 9.6vw;
`;

export const ItemTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 23.17px;
  text-align: center;
  color: #191919;
  margin-top: 16px;

  ${forMobile} {
    font-size: 12px;
    line-height: 17px;
    margin-top: 8px;
  }
`;

export const SpecialTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #686868;
  margin-bottom: 5px;
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const ErrorBlock = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: flex-end;
`;

export const Input = styled.input`
  padding: 13.5px 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: inset -1px -1px 3px rgba(160, 160, 160, 0.25),
    inset 1px 1px 3px rgba(160, 160, 160, 0.25),
    inset -2px -2px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
  flex: 1;
  display: flex;

  line-height: 21px;
  font-size: 14px;
  color: #191919;
  ::placeholder {
    color: #a3a3a3;
  }
  ${(props) =>
    props.isError &&
    `
  border-color: #F4511E;
`}
  & + ${ErrorBlock} {
    margin-top: 0;
  }
  &:focus {
    border: 1px inside #1e9fd2;
    box-shadow: 0 0 0 2px rgba(30, 159, 210, 0.48);
  }
`;

export const ErrorText = styled.span`
  color: #f4511e;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const Submit = styled.div`
  width: 143px;
  height: 48px; // 13vw;
  background: #031888;
  padding: 14px 16px;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  ${forMobile} {
    width: 60px; // 16vw;
  }
`;

export const MiddleWrap = styled.div`
  ${greaterThanMobile} {
    display: flex;
  }
`;
