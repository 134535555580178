import React, { useState } from "react";
import BurgerMenu from "./BurgerMenu";
import {
  Wrapper,
  Burger,
  Menu,
  DropDownSelector,
  Button,
  MemberMenu,
  DropDownList,
  Option,
  Icon,
} from "./styled";

const TopNav = ({ blur, menuLogin = false }) => {
  const [isShowMemberList, setIsShowMemberList] = useState(false);
  const menu = menuLogin ? ["登入"] : ["登出"];
  const showMemberList = () => {
    setIsShowMemberList(!isShowMemberList);
  };

  return (
    <BurgerMenu>
      {({ handleShowMenu }) => (
        <>
          <Wrapper
            blur={blur}
            homeLink={"/"}
            leftIcon={
              handleShowMenu && (
                <Burger
                  src="../assets/images/burger.png"
                  alt="burger image"
                  onClick={() => handleShowMenu(true)}
                />
              )
            }
            handleShowMenu={handleShowMenu}
          >
            <Menu>
              <DropDownSelector>
                <Button
                  src="../assets/images/person.png"
                  alt="person image"
                  onClick={showMemberList}
                />
              </DropDownSelector>
            </Menu>
            {!!isShowMemberList && (
              <MemberMenu>
                <DropDownList>
                  {menu.map((option, idx) => (
                    <a href={"/"}>
                      <Option
                        key={idx}
                        onClick={() => {
                          setIsShowMemberList(false);
                          // Login out
                          localStorage.clear();
                        }}
                      >
                        <Icon
                          src="../assets/images/logout_Icon.png"
                          alt="logout_Icon image"
                        />
                        {option}
                      </Option>
                    </a>
                  ))}
                </DropDownList>
              </MemberMenu>
            )}
          </Wrapper>
        </>
      )}
    </BurgerMenu>
  );
};

export default TopNav;
