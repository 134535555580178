import styled from "styled-components";
import { greaterThanMobile } from "../../../breakpoints";

export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #282828;
  margin: 16px 0;
`;

export const Description = styled.h1`
  ont-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #191919;
  margin-bottom: ${(props) => (props.last ? "24px" : 0)};
  ${(props) =>
    props.loading &&
    `
  animation: pulse 1s infinite;
  `}
`;

export const BellIcon = styled.img`
  margin-right: 10px;
  width: 18px;
  height: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.div`
  display: flex;
  flex: 0.48;
  align-items: center;
  padding: 10px 24px;
  border: 1px solid #031888;
  box-shadow: 2px 2px 10px rgba(151, 151, 151, 0.3);
  border-radius: 5px;
  cursor: pointer;
  ${(props) => props.dark && `background-color: #031888`}
  ${(props) => !props.last && `margin-bottom:24px;`}
`;

export const ButtonTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.dark ? "#FFFFFF" : "#031888")};
  ${(props) => props.end && `justify-content: flex-end`}
  ${(props) => !props.end && `width: 30vw`}
  display: flex;
  ${(props) => !props.end && `flex: 1`}
`;

export const ListWrap = styled.div``;
