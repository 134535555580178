const breakpoints = [360, 768, 992, 1280];

const breakpointsWithUnit = breakpoints.map((breakpoint) => `${breakpoint}px`);

breakpointsWithUnit.miniMobile = breakpoints[0];
breakpointsWithUnit.mobile = breakpoints[1];
breakpointsWithUnit.tablet = breakpoints[2];
breakpointsWithUnit.desktop = breakpoints[3];

// < 360
export const forMiniMobile = `@media (max-width: ${
  breakpointsWithUnit.miniMobile - 1
}px)`;

// < 768
export const forMobile = `@media (max-width: ${
  breakpointsWithUnit.mobile - 1
}px)`;

// >= 768
export const greaterThanMobile = `@media (min-width: ${breakpointsWithUnit.mobile}px)`;

// < 992
export const lessThanMiniTablet = `@media (max-width: ${
  breakpointsWithUnit.tablet - 1
}px)`;

// > 992
export const greaterThanMiniTablet = `@media (min-width: ${breakpointsWithUnit.tablet}px)`;

// 768 ~ 1279
export const forAllTablet = `@media (min-width: ${
  breakpointsWithUnit.mobile
}px) and (max-width: ${breakpointsWithUnit.desktop - 1}px)`;

// < 1280
export const lessThanDesktop = `@media (max-width: ${
  breakpointsWithUnit.desktop - 1
}px)`;

// >= 1280
export const forDesktop = `@media (min-width: ${breakpointsWithUnit.desktop}px)`;

// 992 ~ 1279
export const forTablet = `@media (min-width: ${
  breakpointsWithUnit.tablet
}px) and (max-width: ${breakpointsWithUnit.desktop - 1}px)`;

// 768 ~ 991
export const forMiniTablet = `@media (min-width: ${
  breakpointsWithUnit.mobile
}px) and (max-width: ${breakpointsWithUnit.tablet - 1}px)`;

export default breakpointsWithUnit;
