import styled from "styled-components";
import HeaderWrap from "../Header";

export const Wrapper = styled(HeaderWrap)`
  background-position: left bottom;
  background-repeat: repeat-x;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010;

  ${(props) =>
    props.blur &&
    `
  filter: blur(3px);
  -webkit-filter: blur(3px);
  `}
`;

export const Burger = styled.img`
  width: 24px;
  height: 24px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  color: #616161;
`;

export const DropDownSelector = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
`;

export const Button = styled.img`
  color: #595959;
  display: flex;
  padding: 12px;
  width: 48px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &:active {
    background-color: #e8e8e8;
  }
`;

export const MemberMenu = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 16px;
  max-width: 168px;
  width: 30vw;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 15px 30px rgba(82, 79, 79, 0.1);
  z-index: 1010;
  padding: 4px 0;
`;

export const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  font-size: 16px;
`;

export const Option = styled.li`
  padding: 10px 16px;
  line-height: 23.17px;
  font-size: 16x;
  color: #686868;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    color: #191919;
  }

  &:active {
    background-color: #fefefe;
    color: #191919;
  }
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 12px;
  width: 18px;
  height: 18px;
`;
