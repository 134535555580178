import React from "react";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../routes";
import {
  Overlay,
  OverlayBackground,
  Wrap,
  Title,
  Subtitle,
  Description,
  Login,
  LogoIcon,
  LoginIcon,
} from "../styled";

const LoginAlert = ({ actionHandler }) => {
  const navigate = useNavigate();
  return (
    <Overlay>
      <OverlayBackground isTransparentBackground={true}>
        <Wrap>
          <Title>
            <LogoIcon src="../assets/images/logo.png" alt="logo image" />
            JCAI
          </Title>
          <Subtitle>與AI聊天學英文</Subtitle>
          <Description>3個月讓你流利聽說英文</Description>
          <Login onClick={actionHandler}>
            <LoginIcon
              src="../assets/images/google_Icon.png"
              alt="google_Icon image"
            />
            使用 Google 登入
          </Login>
          <div style={{ marginTop: "24px", display: "flex" }}>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "17px",
                color: "#686868",
              }}
            >
              登入即同意
            </p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "17px",
                color: "#031888",
              }}
              onClick={() => navigate(routeConfig.privacy)}
            >
              隱私權政策
            </p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "17px",
                color: "#686868",
              }}
            >
              與
            </p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "17px",
                color: "#031888",
              }}
              onClick={() => navigate(routeConfig.userRules)}
            >
              使用者條款
            </p>
          </div>
        </Wrap>
      </OverlayBackground>
    </Overlay>
  );
};

export default LoginAlert;
